import { GraphQLClient } from 'graphql-request';
import { createContext, PropsWithChildren, useContext, useMemo } from 'react';

const ClientContext = createContext<GraphQLClient | undefined>(undefined);

export function GraphQLClientProvider({
  apiUrl,
  apiKey,
  children,
}: PropsWithChildren<{ apiUrl: string; apiKey: string }>) {
  const client = useMemo(() => {
    const endpoint = new URL('graphql/', apiUrl).toString();
    return new GraphQLClient(endpoint, {
      headers: {
        'X-Api-Key': apiKey,
      },
      fetch: window.fetch,
    });
  }, [apiUrl, apiKey]);

  return <ClientContext.Provider value={client}>{children}</ClientContext.Provider>;
}

export function useGraphQLClient() {
  const client = useContext(ClientContext);
  if (!client) {
    throw new Error('useGraphQLClient needs to be used within GraphQLClientProvider tree.');
  }
  return client;
}
