import { LiteralUnion } from 'type-fest';

import { initializeReactApps } from '@/initializeReact';
import { InlineOptions, WidgetComponent, WidgetInstance, WidgetTheme, WidgetType } from '@/types';

import { WIDGET_PREFIXES } from './utils';

// Make sure to update `docs/usage.md` when changing
const defaultOptions: InlineOptions = {
  apiKey: null,
  apiUrl: null,
  disableStructuredData: false,
  mainColor: null, // default color is set in theme
  momentId: null,
  playlistId: null,
  presetId: null,
  theme: WidgetTheme.Light,
  trackingId: null,
  measurementId: null,
  videoId: null,
  showThumbnailsSlider: true,
  showMomentsBelowVideo: false,
  showSocialIcons: false,
  autoplay: true,
};

type Command = 'init';

// TODO: change this to `unknown` and add validation
type CommandOptions = any;

type GlobalObject = {
  q?: [LiteralUnion<Command, string>, CommandOptions][];
};

/**
 * The initialization function.
 *
 * It gathers the widget instance declarations from the DOM, using the special id prefix and `type`
 * argument. Then it creates a React DOM tree with the `Widget` component, skipping already
 * initialized ones and widgets of different `type`.
 */
export function startWidget(type: WidgetType, Widget: WidgetComponent) {
  const prefix = WIDGET_PREFIXES[type];
  const instances = Object.keys(window)
    .filter((id) => id.startsWith(prefix))
    .map((id) => {
      return {
        id,
        ...window[id as any],
      } as WidgetInstance;
    });

  instances.forEach((instance) => {
    // @ts-ignore
    const globalObject = window[instance.id] as GlobalObject;
    const queue = globalObject.q;

    queue?.forEach(([command, options]) => {
      /**
       * Command system is a leftover from the legacy widgets.
       * Adding new commands is discouraged. Prefer adding new configuration options.
       * If a new command is added, make sure to run the `init` as last one in the queue.
       */
      if (command === 'init') {
        if (typeof options !== 'object' && typeof options !== 'undefined') return;

        if (!instance.isInitialized) {
          // eslint-disable-next-line no-param-reassign
          instance.options = { ...defaultOptions, ...removeUnknownKeys(options) };
          initializeReactApps({ instance, prefix, Widget });
        }
      } else {
        console.warn(`[Seeen Widget] Unknown command "${command}"`);
      }
    });
  });
}

function removeUnknownKeys(options: InlineOptions) {
  const knownKeys = Object.keys(defaultOptions);
  const out: Partial<InlineOptions> = {};
  knownKeys.forEach((key) => {
    // @ts-ignore
    out[key] = options[key];
  });
  return out;
}
