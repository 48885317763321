import { VideoItemType } from '@entertainment-ai/react-component-library';
import React from 'react';

import { useGoogleAnalyticsEx } from '@/features/analytics';
import { NormalizedVideoItem } from '@/features/normalize-data';

import { ProgressState, useVideoPercentage } from './useVideoPercentage';

export const useVideoAnalytics = (item: NormalizedVideoItem) => {
  const trackVideo = useGoogleAnalyticsEx('video');
  const isMoment = item.type === VideoItemType.Moment;
  const videoId = item.video.id;
  const momentId = isMoment ? item.id : undefined;

  const [videoPercentage] = useVideoPercentage(videoId, VideoItemType.Video);
  const [momentPercentage] = useVideoPercentage(momentId, VideoItemType.Moment);

  const playedPercentage = React.useCallback(
    (progress: ProgressState) => {
      if (momentId) {
        const { playedSeconds } = progress;
        const moment = item.moments.find(({ id }) => id === momentId);
        if (moment) {
          const { startTimestamp, endTimestamp } = moment;
          if (playedSeconds > startTimestamp && playedSeconds < endTimestamp) {
            const momentProgressFraction =
              (playedSeconds - startTimestamp) / (endTimestamp - startTimestamp);
            momentPercentage({ ...progress, played: momentProgressFraction });
          }
        }
      }
      videoPercentage(progress);
    },
    [momentId],
  );

  const onVideoStart = React.useCallback(() => {
    if (isMoment) {
      trackVideo('playMoment', { momentId });
    } else {
      trackVideo('playVideo', { videoId });
    }
  }, [isMoment]);

  const onVideoEnd = React.useCallback(() => {
    if (isMoment) {
      trackVideo('endMoment', { momentId });
    } else {
      trackVideo('endVideo', { videoId });
    }
  }, [isMoment]);

  const onEndcardShow = React.useCallback(() => {
    if (isMoment) {
      trackVideo('showMomentEndcard', { momentId });
    } else {
      trackVideo('showVideoEndcard', { videoId });
    }
  }, [isMoment]);

  return {
    playedPercentage,
    onVideoStart,
    onVideoEnd,
    onEndcardShow,
  };
};
