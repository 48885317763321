import { gql } from 'graphql-request';
import { useQuery } from 'react-query';

import {
  MomentItemPick,
  NormalizedVideoItem,
  normalizeMoment,
  normalizeVideo,
  VideoItemPick,
} from '@/features/normalize-data';
import { useGraphQLClient } from '@/graphql-client';
import { STRUCTURED_DATA_MOMENT_ID_PARAM } from '@/utils';

import { PlayerType } from './PlayerWidget.types';

const END_CARD_FRAGMENT = gql`
  fragment EndCards on PublicEndCardType {
    id
    callToActionButton {
      id
      label
      icon
      url
    }
    promoCodeButton {
      id
      label
      text
    }
    watchNextButtonVisible
    socialMediaButtons {
      platformName
      accountUrl
      label
      id
    }
    cardType
    origin
    customCode
    background
    border
    id
    headline
    subline
    videoControlButtons
    cardSize
    backgroundImageUrl
    overlay
    cardOrientation
    backgroundImageSettings
    ctaButton1 {
      name
      id
      text
      url
      background
      border
      icon
    }
    ctaButton2 {
      name
      id
      text
      url
      background
      border
      icon
    }
  }
`;

const PROMOTE_RESPONSE_FRAGMENT = gql`
  fragment PromotedContentFragment on VideoPromotedContentSettingsType {
    promoteObjects
    promoteAtStart
    promoteAtEnd
    promotedObjectsLabel
  }
`;

const GET_VIDEO_BY_ID = gql`
  ${PROMOTE_RESPONSE_FRAGMENT}
  ${END_CARD_FRAGMENT}

  query GetVideoById($id: ID!, $playerLocationUrl: URL, $clipUrlMomentIdParam: String) {
    publicVideoById(id: $id) {
      id
      duration
      slug
      structuredData(
        playerLocationUrl: $playerLocationUrl
        clipUrlMomentIdParam: $clipUrlMomentIdParam
      )
      thumbnailUrl
      title
      captions {
        language
        languageCode
        captionFileUrl
      }
      shareSocialMediaIcons {
        platformName
        visible
      }
      videoFileUrl
      videoStreams {
        standard
        manifestFileUrl
      }
      aspectRatio
      moments {
        id
        title
        duration
        startTimestamp
        endTimestamp
        thumbnailUrl
        endCard {
          ...EndCards
        }
      }
      promotedContentSettings {
        ...PromotedContentFragment
      }
      objects {
        title
        url
        promote
        id
        imageUrl
      }
      endCard {
        ...EndCards
      }
      monetization {
        orgMonetization {
          id
          active
          default
          monetizationUrl
          name
        }
        useDefaultMonetization
        isMonetizationEnabled
        adType
        adDensity
        adLocation
      }
      endCard {
        ...EndCards
      }
      orgLogoUrl
      orgLogoPosition
      orgLogoEnabled
      ctaTimestamps {
        id
        name
        startTimestamp
        endTimestamp
        ctas {
          id
          button {
            id
            name
            url
            text
            background
            border
            icon
          }
          image {
            id
            name
            url
            imageUrl
          }
          config
          order
        }
      }
    }
  }
`;

interface VideoQueryResponse {
  publicVideoById: VideoItemPick | null;
}

const GET_MOMENT_BY_ID = gql`
  ${PROMOTE_RESPONSE_FRAGMENT}
  ${END_CARD_FRAGMENT}

  query GetMomentById($id: ID!) {
    publicMomentById(id: $id) {
      id
      startTimestamp
      endTimestamp
      duration
      slug
      structuredData
      thumbnailUrl
      shareSocialMediaIcons {
        platformName
        visible
      }
      title
      video {
        id
        duration
        title
        slug
        thumbnailUrl
        captions {
          language
          languageCode
          captionFileUrl
        }
        videoFileUrl
        videoStreams {
          standard
          manifestFileUrl
        }
        promotedContentSettings {
          ...PromotedContentFragment
        }
        aspectRatio
        objects {
          id
          title
          category
          url
          imageUrl
          promote
        }
        monetization {
          orgMonetization {
            id
            active
            default
            monetizationUrl
            name
          }
          useDefaultMonetization
          isMonetizationEnabled
          adType
          adDensity
          adLocation
        }
        ctaTimestamps {
          id
          name
          startTimestamp
          endTimestamp
          ctas {
            id
            button {
              id
              name
              url
              text
              background
              border
              icon
            }
            image {
              id
              name
              url
              imageUrl
            }
            config
            order
          }
        }
      }
      endCard {
        ...EndCards
      }
    }
  }
`;

interface MomentQueryResponse {
  publicMomentById: MomentItemPick | null;
}

interface Variables {
  id: string;
  playerLocationUrl?: string;
  clipUrlMomentIdParam?: string;
}

interface UsePlayerDataOptions<Type extends PlayerType> {
  id: string;
  type: Type;
  playerLocationUrl?: string;
}

export function usePlayerData<Type extends PlayerType>({
  id,
  type,
  playerLocationUrl,
}: UsePlayerDataOptions<Type>) {
  const isVideo = type === PlayerType.Video;
  const QUERY = isVideo ? GET_VIDEO_BY_ID : GET_MOMENT_BY_ID;

  type Response = Type extends PlayerType.Video ? VideoQueryResponse : MomentQueryResponse;

  const client = useGraphQLClient();
  const query = useQuery(['player', { id, type, playerLocationUrl }], {
    queryFn: () =>
      client.request<Response, Variables>(QUERY, {
        id,
        playerLocationUrl: isVideo ? playerLocationUrl : undefined,
        clipUrlMomentIdParam: isVideo ? STRUCTURED_DATA_MOMENT_ID_PARAM : undefined,
      }),
  });

  return {
    ...query,
    data: normalizeData(query.data),
  };
}

function normalizeData(
  data: VideoQueryResponse | MomentQueryResponse | undefined,
): NormalizedVideoItem | undefined {
  if (!data) return undefined;

  if ('publicMomentById' in data) {
    if (!data.publicMomentById) return undefined;
    return normalizeMoment(data.publicMomentById);
  }

  if (!data.publicVideoById) return undefined;
  return normalizeVideo(data.publicVideoById);
}
