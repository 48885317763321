import { gql } from 'graphql-request';
import { useCallback } from 'react';

import { useGraphQLClient } from '@/graphql-client';

const AddVideoViewMutation = gql`
  mutation ViewVideoMutation($videoId: ID!) {
    viewVideo(videoId: $videoId) {
      ok
      errors {
        ... on ErrorInterface {
          field
          messages
          name
        }
      }
    }
  }
`;

const AddMomentViewMutation = gql`
  mutation ViewMomentMutation($momentId: ID!) {
    viewMoment(momentId: $momentId) {
      ok
      errors {
        ... on ErrorInterface {
          field
          messages
          name
        }
      }
    }
  }
`;

export function useAddView() {
  const client = useGraphQLClient();

  return useCallback(
    (id: string, isMoment: boolean) => {
      const variables = isMoment ? { momentId: id } : { videoId: id };
      const query = isMoment ? AddMomentViewMutation : AddVideoViewMutation;
      client.request(query, variables).catch(() => {});
    },
    [client],
  );
}
