import { gql } from 'graphql-request';
import { useQuery } from 'react-query';

import { NormalizedWidgetPresetData, WidgetPresetData } from '@/api-types';
import { normalizeEndCard } from '@/features/normalize-data';
import { useGraphQLClient } from '@/graphql-client';
import { Nullable } from '@/types';

const QUERY = gql`
  query GetPublicWidgetPreset($id: ID!) {
    publicWidgetPresetById(id: $id) {
      id
      googleAnalyticsTrackingId
      googleAnalytics4MeasurementId
      style {
        mainColor
        colorTheme
      }
      endCard {
        id
        callToActionButton {
          id
          label
          icon
          url
        }
        promoCodeButton {
          id
          label
          text
        }
        socialMediaButtons {
          id
          label
          platformName
          accountUrl
        }
      }
    }
  }
`;

interface ResponseData {
  publicWidgetPresetById: WidgetPresetData | null;
}

interface Variables {
  id: string;
}

export function useWidgetPreset(presetId: Nullable<string>) {
  const client = useGraphQLClient();
  const query = useQuery(['preset', presetId], {
    queryFn: () => client.request<ResponseData, Variables>(QUERY, { id: presetId || '' }),
    enabled: typeof presetId === 'string',
  });

  return {
    ...query,
    data: normalizeData(query.data?.publicWidgetPresetById),
  };
}

function normalizeData(data?: WidgetPresetData | null): NormalizedWidgetPresetData | undefined {
  if (!data) return undefined;
  return {
    ...data,
    endCard: normalizeEndCard(data.endCard),
  };
}
