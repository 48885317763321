import { NormalizedWidgetPresetData } from '@/api-types';
import { NormalizedVideoItem } from '@/features/normalize-data';
import { mergeOptionsWithPreset, WidgetConfiguration } from '@/features/widget-preset';
import { InlineOptions, Nullable } from '@/types';

export function computeWidgetConfiguration(
  options: InlineOptions,
  preset: NormalizedWidgetPresetData | undefined,
  playerData: Nullable<NormalizedVideoItem>,
): WidgetConfiguration {
  const { endCard, ...mergedOptions } = mergeOptionsWithPreset(options, preset);
  return {
    ...mergedOptions,
    endCard: endCard || playerData?.endCard,
  };
}
