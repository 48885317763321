import {
  CTAButtonProps,
  EndcardOrientationOption,
  EndCardType,
  HorizontalAlignment,
  ObjectAnnotation,
  OverlaysFontFamilyOption,
  SocialMediaTypes,
  SupportedIconType,
  VerticalAlignment,
  VideoMonetization,
  WatermarkPositionsBackendProps,
} from '@entertainment-ai/react-component-library';

import { Nullable } from './types';

export enum VideoStreamStandard {
  HLS = 'APPLE_HLS',
  DASH = 'DASH_ISO',
}

export interface VideoStream {
  standard: VideoStreamStandard;
  manifestFileUrl: string;
}

export interface Captions {
  language: string;
  languageCode: string;
  captionFileUrl: string;
}

export interface CallToActionButton {
  id: string;
  label: string;
  icon: 'CART_PLUS' | 'ARROW_RIGHT' | 'PLAY' | 'SHARE_ARROW' | 'SHARE_BG';
  url: string;
}

export interface PromoCodeButton {
  id: string;
  label: string;
  text: string;
}

export interface ShareSocialIcons {
  platformName: string;
  visible: boolean;
}

export interface EndCard {
  id: string;
  callToActionButton: CallToActionButton | null;
  cardOrientation: EndcardOrientationOption.HORIZONTAL | EndcardOrientationOption.VERTICAL;
  promoCodeButton?: {
    label: string;
    text: string;
  };
  watchNextButtonVisible: boolean;
  socialMediaButtons?: SocialMediaTypes[];
  cardSize: string;
  overlay: {
    color: string;
    opacity: string;
  };
  background: {
    color: string;
    opacity: string;
  };
  backgroundImageSettings: {
    size: string;
    top: string;
    left: string;
    horizontal: HorizontalAlignment;
    vertical: VerticalAlignment;
  };
  border: {
    width: string;
    color: string;
    radius: string;
  };
  videoControlButtons: {
    iconsColor: string;
    backgroundColor: string;
    backgroundOpacity: string;
  };
  headline: {
    text: string;
    font: OverlaysFontFamilyOption;
    fontSize: string;
    color: string;
  };
  subline: {
    text: string;
    font: OverlaysFontFamilyOption;
    fontSize: string;
    color: string;
  };
  backgroundImageUrl: string;
  cardType: string;
  ctaButton1: CTAButtonProps;
  ctaButton2: CTAButtonProps;
  origin: EndCardType;
  customCode: string | null;
}

export interface NormalizedEndCard extends Omit<EndCard, 'callToActionButton'> {
  callToActionButton: {
    id: string;
    label: string;
    icon: SupportedIconType;
    url: string;
  } | null;
}

export interface PromotedContentTypes {
  promoteObjects: boolean;
  promoteAtStart: boolean;
  promoteAtEnd: boolean;
  promotedObjectsLabel: string;
}

export interface VideoInterface {
  id: string;
  duration: number;
  slug: string;
  structuredData: string | null;
  thumbnailUrl: string | null;
  title: string;
  captions: Captions[];
  videoFileUrl: string;
  videoStreams: VideoStream[] | null;
  endCard: EndCard | null;
  promotedContentSettings?: PromotedContentTypes;
  objects: ObjectAnnotation[];
  moments: MomentInterface[];
  shareSocialMediaIcons: ShareSocialIcons[];
  ctaTimestamps: VideoCTATimestampsResponse['ctaTimestamps'];
  monetization: VideoMonetization;
  orgLogoUrl: string;
  orgLogoEnabled: boolean;
  orgLogoPosition: WatermarkPositionsBackendProps;
  aspectRatio: string;
}

export interface MomentInterface {
  id: string;
  startTimestamp: number;
  endTimestamp: number;
  duration: number;
  slug: string;
  structuredData: string | null;
  thumbnailUrl: string | null;
  shareSocialMediaIcons: ShareSocialIcons[];
  title: string;
  video: VideoInterface;
  endCard: EndCard | null;
}

export interface WidgetPresetI {
  id: string;
  googleAnalyticsTrackingId: string | null;
  googleAnalytics4MeasurementId: string | null;
  style: WidgetStyle;
  endCard: EndCard | null;
}

export type WidgetPresetData = Pick<
  WidgetPresetI,
  'id' | 'googleAnalyticsTrackingId' | 'googleAnalytics4MeasurementId' | 'style'
> & {
  endCard: EndCard | null;
};

export type NormalizedWidgetPresetData = Pick<
  WidgetPresetI,
  'id' | 'googleAnalyticsTrackingId' | 'googleAnalytics4MeasurementId' | 'style'
> & {
  endCard: NormalizedEndCard | null;
};

export enum WidgetColorThemeApi {
  Light = 'LIGHT',
  Dark = 'DARK',
}

export interface WidgetStyle {
  /** in hex format */
  mainColor: string;
  colorTheme: WidgetColorThemeApi;
}

export enum PlaylistContentType {
  Videos = 'VIDEOS',
  Moments = 'MOMENTS',
}

export type VideoCTATimestampCtasResponse = { id: string } & {
  button: Nullable<{ id: string; name: string }>;
  image: Nullable<{ id: string; name: string }>;
  order: number;
  config: Nullable<any>;
};

export type VideoCTATimestampResponse = { id: string } & {
  name: string;
  startTimestamp: number;
  endTimestamp: number;
  ctas: VideoCTATimestampCtasResponse[];
};

export interface VideoCTATimestampsResponse {
  ctaTimestamps: ({ id: string } & VideoCTATimestampResponse)[];
}
