import { PropsWithChildren } from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

import { Nullable, WidgetTheme } from '@/types';

const common = {
  primary: '#da2d6a', // this is default value, it can be overwritten by customizing widget appearance in Creator Suite
  secondary: '#5c5e7a',
  outline: '#dbdee6',
  font: '#181927',
  fontDarker: '#151618',
  fontSecondary: '#9798aa',
  invertFont: '#fff',
  paragraphFont: '#3d3e52',
  shadowColor: '#000000',
  bg: '#ffffff',
  bgSecondary: '#3b3d4f',
  bgLight: '#f6f6fb',
  bgLightSecondary: '#f1f1f7',
  navy: '#121221',
  greyLink: '#bbbcd0',
  headingFontFamily: 'Cairo, sans-serif',
  headingFontWeight: 'bold',
  videoBorderRadius: '0px',
  gridBorderRadius: '0px',
  imageBorderRadius: '0px',
};

export const lightTheme = {
  ...common,
  name: 'LightTheme',
  titleColor: 'rgba(0, 0, 0, 0.6)',
  showSliderMasks: true,
};

export const darkTheme = {
  ...common,
  name: 'DarkTheme',
  titleColor: 'rgba(255, 255, 255, 0.6)',
  showSliderMasks: false,
};

type Props = PropsWithChildren<{
  theme: Nullable<WidgetTheme>;
  mainColor: Nullable<string>;
}>;

export function ThemeProvider({ theme, mainColor, children }: Props) {
  const baseTheme = theme === WidgetTheme.Dark ? darkTheme : lightTheme;
  const computedTheme = {
    ...baseTheme,
    ...(typeof mainColor === 'string' && { primary: mainColor }),
  };

  return <StyledThemeProvider theme={computedTheme}>{children}</StyledThemeProvider>;
}
