import {
  ObjectAnnotation,
  SupportedIconType,
  VideoItemType,
  VideoMonetization,
  WatermarkPositionsBackendProps,
} from '@entertainment-ai/react-component-library';

import {
  CallToActionButton,
  Captions,
  EndCard,
  MomentInterface,
  NormalizedEndCard,
  PromotedContentTypes,
  ShareSocialIcons,
  VideoCTATimestampsResponse,
  VideoInterface,
  VideoStream,
  VideoStreamStandard,
} from '@/api-types';
import { Nullable } from '@/types';

export interface CarouselMoment {
  id: string;
  title: string;
  duration: number;
  startTimestamp: number;
  endTimestamp: number;
  thumbnailUrl: string | null;
  slug: string;
  endCard: NormalizedEndCard | null;
}

export interface NormalizedVideoItem {
  type: VideoItemType;
  id: string;
  title: string;
  slug: string;
  structuredData: string | null;
  thumbnailUrl: string | null;
  captions: Captions[];
  videoDuration: number;
  videoFileUrl: string;
  endCard: NormalizedEndCard | null;
  ctaTimestamps: VideoCTATimestampsResponse['ctaTimestamps'];
  monetization?: VideoMonetization;
  orgLogoUrl?: string;
  orgLogoEnabled?: boolean;
  orgLogoPosition?: WatermarkPositionsBackendProps;
  aspectRatio?: string;

  /** only in moment */
  video: {
    monetization?: VideoMonetization;
    type: VideoItemType.Video;
    id: string;
    title: string;
    slug: string;
    thumbnailUrl: string | null;
    promotedContentSettings?: PromotedContentTypes;
    objects: ObjectAnnotation[];
    aspectRatio?: string;
    shareSocialMediaIcons?: ShareSocialIcons[];
  };

  /** only in video */
  moments: CarouselMoment[];
}

export type MomentItemPick = Pick<
  MomentInterface,
  | 'id'
  | 'startTimestamp'
  | 'endTimestamp'
  | 'duration'
  | 'slug'
  | 'structuredData'
  | 'thumbnailUrl'
  | 'title'
  | 'endCard'
> & {
  video: Pick<
    VideoInterface,
    | 'id'
    | 'duration'
    | 'title'
    | 'slug'
    | 'thumbnailUrl'
    | 'captions'
    | 'videoFileUrl'
    | 'videoStreams'
    | 'objects'
    | 'aspectRatio'
  >;
};

export function normalizeMoment(momentData: MomentItemPick): NormalizedVideoItem | undefined {
  if (!momentData) return undefined;
  const { video, ...moment } = momentData;
  const endCard = normalizeEndCard(moment.endCard);
  return normalizeTime({
    videoDuration: video.duration,
    id: moment.id,
    slug: moment.slug,
    structuredData: moment.structuredData,
    thumbnailUrl: moment.thumbnailUrl,
    title: moment.title,
    type: VideoItemType.Moment,
    captions: video.captions,
    videoFileUrl: getVideoUrl(video.videoFileUrl, video.videoStreams),
    endCard,
    video: {
      type: VideoItemType.Video,
      ...video,
    },
    moments: [{ ...moment, endCard }],
    ctaTimestamps: [],
  });
}

export type VideoItemPick = Pick<
  VideoInterface,
  | 'id'
  | 'duration'
  | 'slug'
  | 'structuredData'
  | 'thumbnailUrl'
  | 'title'
  | 'captions'
  | 'videoFileUrl'
  | 'videoStreams'
  | 'endCard'
  | 'promotedContentSettings'
  | 'objects'
  | 'ctaTimestamps'
  | 'monetization'
  | 'orgLogoUrl'
  | 'orgLogoEnabled'
  | 'orgLogoPosition'
  | 'aspectRatio'
> & {
  moments:
    | Pick<
        MomentInterface,
        | 'id'
        | 'title'
        | 'duration'
        | 'startTimestamp'
        | 'endTimestamp'
        | 'thumbnailUrl'
        | 'slug'
        | 'endCard'
      >[]
    | null;
};

export function normalizeVideo(video: VideoItemPick | null): NormalizedVideoItem | undefined {
  if (!video) return undefined;
  return normalizeTime({
    id: video.id,
    slug: video.slug,
    structuredData: video.structuredData,
    thumbnailUrl: video.thumbnailUrl,
    title: video.title,
    type: VideoItemType.Video,
    captions: video.captions,
    videoDuration: video.duration,
    videoFileUrl: getVideoUrl(video.videoFileUrl, video.videoStreams),
    endCard: normalizeEndCard(video.endCard),
    moments:
      video.moments?.map((moment) => {
        return { ...moment, endCard: normalizeEndCard(moment.endCard) };
      }) || [],
    video: {
      type: VideoItemType.Video,
      ...video,
    },
    ctaTimestamps: video.ctaTimestamps,
    monetization: video.monetization,
    orgLogoUrl: video.orgLogoUrl,
    orgLogoEnabled: video.orgLogoEnabled,
    orgLogoPosition: video.orgLogoPosition,
    aspectRatio: video.aspectRatio,
  });
}

const toSec = (ms: Nullable<number>) => (ms ? ms / 1000 : 0);

function normalizeTime(videoItem: NormalizedVideoItem): NormalizedVideoItem {
  return {
    ...videoItem,
    videoDuration: toSec(videoItem.videoDuration),
    moments: videoItem.moments?.map((moment) => {
      return {
        ...moment,
        duration: toSec(videoItem.videoDuration),
        startTimestamp: toSec(moment.startTimestamp),
        endTimestamp: toSec(moment.endTimestamp),
      };
    }),
  };
}

function getVideoUrl(videoFileUrl: string, videoStreams: VideoStream[] | null) {
  if (!videoStreams || videoStreams.length === 0) {
    return videoFileUrl;
  }
  return getHls(videoStreams) || videoFileUrl;
}

function getHls(videoStreams: VideoStream[]) {
  return (videoStreams || []).find(({ standard }) => standard === VideoStreamStandard.HLS)
    ?.manifestFileUrl;
}

const iconMap: Record<CallToActionButton['icon'], SupportedIconType> = {
  ARROW_RIGHT: 'ArrowRight',
  CART_PLUS: 'CartPlus',
  PLAY: 'Play',
  SHARE_ARROW: 'ShareArrow',
  SHARE_BG: 'ShareBg',
};

export function normalizeEndCard(endCard: Nullable<EndCard>): NormalizedEndCard | null {
  if (!endCard) {
    return null;
  }

  if (!endCard.callToActionButton) {
    return { ...endCard, callToActionButton: null };
  }

  return {
    ...endCard,
    callToActionButton: {
      ...endCard.callToActionButton,
      icon: iconMap[endCard.callToActionButton.icon],
    },
  };
}
