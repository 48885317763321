import { NormalizedEndCard, NormalizedWidgetPresetData, WidgetColorThemeApi } from '@/api-types';
import { InlineOptions, Nullable, WidgetTheme } from '@/types';

const themeMap: Record<WidgetColorThemeApi, WidgetTheme> = {
  [WidgetColorThemeApi.Dark]: WidgetTheme.Dark,
  [WidgetColorThemeApi.Light]: WidgetTheme.Light,
};

export interface WidgetConfiguration extends InlineOptions {
  endCard?: Nullable<NormalizedEndCard>;
}

export function mergeOptionsWithPreset(
  options: InlineOptions,
  preset: NormalizedWidgetPresetData | undefined,
): WidgetConfiguration {
  if (!preset) {
    return options;
  }

  const {
    endCard,
    googleAnalyticsTrackingId,
    googleAnalytics4MeasurementId,
    style: { mainColor, colorTheme },
  } = preset;

  return {
    ...options,
    mainColor,
    theme: themeMap[colorTheme],
    trackingId: googleAnalyticsTrackingId,
    measurementId: googleAnalytics4MeasurementId,
    endCard,
  };
}
