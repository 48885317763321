import { render } from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import { WidgetComponent, WidgetInstance } from '@/types';
import { getWidgetElementId, waitForElement, waitForElementToBeRemoved } from '@/utils';

import { GraphQLClientProvider } from './graphql-client';
import { getWidgetId } from './utils';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
    mutations: {
      retry: false,
    },
  },
});

export function initializeReactApps({
  instance,
  prefix,
  Widget,
}: {
  instance: WidgetInstance;
  prefix: string;
  Widget: WidgetComponent;
}) {
  const { apiKey, apiUrl } = instance.options;

  const widgetId = getWidgetId(prefix, instance.id);

  if (!apiKey || !apiUrl) {
    console.error('apiKey and apiUrl options are required');
    return;
  }

  const widgetDomContainerSelector = `#${getWidgetElementId(widgetId)}`;
  waitForElement(widgetDomContainerSelector).then((widgetDomContainer) => {
    render(
      <GraphQLClientProvider apiKey={apiKey} apiUrl={apiUrl}>
        <QueryClientProvider client={queryClient}>
          <Widget widgetId={widgetId} options={instance.options} />
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </GraphQLClientProvider>,
      widgetDomContainer,
    );

    return waitForElementToBeRemoved(widgetDomContainerSelector);
  });

  // eslint-disable-next-line no-param-reassign
  instance.isInitialized = true;
}
