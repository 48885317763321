import { ComponentType } from 'react';

export type Nullable<T> = T | null | undefined;

export enum WidgetTheme {
  Light = 'light',
  Dark = 'dark',
}

// Make sure to update `docs/usage.md` when changing
export interface InlineOptions {
  apiKey: Nullable<string>;
  apiUrl: Nullable<string>;
  disableStructuredData: Nullable<boolean>;
  mainColor: Nullable<string>;
  momentId: Nullable<string>;
  playlistId: Nullable<string>;
  presetId: Nullable<string>;
  theme: Nullable<WidgetTheme>;
  trackingId: Nullable<string>;
  measurementId: Nullable<string>;
  videoId: Nullable<string>;
  showThumbnailsSlider: Nullable<boolean>;
  showMomentsBelowVideo: Nullable<boolean>;
  showSocialIcons: Nullable<boolean>;
  autoplay: boolean;
}

export interface WidgetComponentProps {
  widgetId: string;
  options: InlineOptions;
}

export type WidgetComponent = ComponentType<WidgetComponentProps>;

export enum WidgetType {
  Carousel,
  Player,
  VerticalCarousel,
}

export interface WidgetInstance {
  id: string;
  isInitialized: boolean;
  options: InlineOptions;
}
