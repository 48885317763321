import { VideoItemType } from '@entertainment-ai/react-component-library';
import React from 'react';

import { useGoogleAnalyticsEx } from '@/features/analytics';

export interface ProgressState {
  played: number; // fraction
  playedSeconds: number; // seconds
}

// how many reports - 10%, 20% etc
export const useVideoPercentage = (
  id: string | undefined,
  type: VideoItemType,
  factorsNum = 10,
) => {
  const trackVideo = useGoogleAnalyticsEx('video');
  const sentValues = React.useRef<number[]>([]);

  React.useEffect(() => {
    sentValues.current = [];
  }, [id]);

  const handler = React.useCallback(
    ({ played }: ProgressState) => {
      const int = Math.floor(played * 100); // percentage number
      const reportableValue = Math.floor(int / factorsNum) * factorsNum;

      if (!sentValues.current.includes(reportableValue)) {
        trackVideo(type === VideoItemType.Video ? 'videoPercentage' : 'momentPercentage', {
          label: `${reportableValue}%`,
          videoId: id,
        });
        sentValues.current = [...sentValues.current, reportableValue];
      }
    },
    [id, factorsNum],
  );

  return [handler] as const;
};
