import { VideoJSPlayerContextProvider } from '@entertainment-ai/react-component-library';
import { useMemo } from 'react';

import { GoogleAnalyticsProvider } from '@/features/analytics';
import { StructuredDataContainer } from '@/features/structured-data';
import { ThemeProvider } from '@/features/theme';
import { useWidgetPreset } from '@/features/widget-preset';
import { InlineOptions, WidgetComponentProps, WidgetType } from '@/types';
import { isSafari, STRUCTURED_DATA_MOMENT_ID_PARAM, WIDGET_ID_PARAM } from '@/utils';

import { PlayerContainer } from './PlayerContainer';
import { PlayerType } from './PlayerWidget.types';
import { computeWidgetConfiguration } from './PlayerWidget.utils';
import { usePlayerData } from './usePlayerData';

export function PlayerWidget({ widgetId, options }: WidgetComponentProps) {
  const playerType = getPlayerType(options);

  const playerLocationUrl = useMemo(() => {
    const url = new URL(window.location.href);
    // remove query param that is automatically injected by API
    url.searchParams.delete(STRUCTURED_DATA_MOMENT_ID_PARAM);
    url.searchParams.set(WIDGET_ID_PARAM, widgetId);
    return url.toString();
  }, []);

  const momentIdForAutoplay = useMomentIdForAutoplay(widgetId);

  if (playerType === undefined || !(options.videoId || options.momentId)) {
    return null;
  }

  return (
    <PlayerWidgetInner
      id={(options.videoId || options.momentId)!}
      playerLocationUrl={playerLocationUrl}
      momentIdForAutoplay={momentIdForAutoplay}
      playerType={playerType}
      options={options}
    />
  );
}

interface PlayerWidgetInnerProps {
  id: string;
  playerType: PlayerType;
  playerLocationUrl: string;
  momentIdForAutoplay?: string;
  options: InlineOptions;
}

function PlayerWidgetInner({
  id,
  playerLocationUrl,
  momentIdForAutoplay,
  playerType,
  options,
}: PlayerWidgetInnerProps) {
  const { data: playerData } = usePlayerData({
    id,
    type: playerType,
    playerLocationUrl,
  });
  const { data: presetData } = useWidgetPreset(options.presetId);

  const configuration = computeWidgetConfiguration(options, presetData, playerData);

  return playerData ? (
    <GoogleAnalyticsProvider
      clientTrackingId={configuration.trackingId}
      clientMeasurementId={configuration.measurementId}
      widgetType={WidgetType.Player}
    >
      <ThemeProvider mainColor={configuration.mainColor} theme={configuration.theme}>
        {!configuration.disableStructuredData ? (
          <StructuredDataContainer structuredData={playerData.structuredData} />
        ) : null}
        <VideoJSPlayerContextProvider>
          <PlayerContainer
            videoItem={playerData}
            momentIdForAutoplay={momentIdForAutoplay}
            configuration={configuration}
            autoplay={!isSafari() ? configuration.autoplay : false}
          />
        </VideoJSPlayerContextProvider>
      </ThemeProvider>
    </GoogleAnalyticsProvider>
  ) : null;
}

function getPlayerType({ videoId, momentId }: Pick<InlineOptions, 'videoId' | 'momentId'>) {
  if (typeof videoId === 'string') {
    return PlayerType.Video;
  }
  if (typeof momentId === 'string') {
    return PlayerType.Moment;
  }
  return undefined;
}

function useMomentIdForAutoplay(widgetId: string) {
  const url = new URL(window.location.href);
  const widgetIdParam = url.searchParams.get(WIDGET_ID_PARAM);
  const momentIdParam = url.searchParams.get(STRUCTURED_DATA_MOMENT_ID_PARAM);

  if (!widgetIdParam || !momentIdParam) return undefined;
  if (widgetIdParam !== widgetId) return undefined;

  return momentIdParam;
}
