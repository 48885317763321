import { WidgetType } from './types';

/** Needs to match with widget id specified in embed code */
export const WIDGET_PREFIXES: Record<WidgetType, string> = {
  [WidgetType.Carousel]: 'seeenCarouselWidget',
  [WidgetType.Player]: 'seeenPlayerWidget',
  [WidgetType.VerticalCarousel]: 'seeenVerticalCarouselWidget',
};

export const WIDGET_ID_PARAM = 'seeenWidgetId';
export const STRUCTURED_DATA_MOMENT_ID_PARAM = 'seeenMomentId';
export const STRUCTURED_DATA_PLAYLIST_ITEM_ID_PARAM = 'seeenPlaylistItemId';

export const getWidgetId = (prefix: string, instanceId: string) => instanceId.replace(prefix, '');

export const getWidgetElementId = (widgetId: string) => `seeen-widget-${widgetId}`;

export const getLightboxElementId = (widgetId: string) => `seeen-widget-lightbox-${widgetId}`;
export const getLightboxElement = (widgetId: string) =>
  document.getElementById(getLightboxElementId(widgetId));

export function waitForElement(selector: string) {
  return new Promise<Element | null>((resolve) => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector));
    }

    const observer = new MutationObserver((_mutations) => {
      if (document.querySelector(selector)) {
        resolve(document.querySelector(selector));
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  });
}

export function waitForElementToBeRemoved(selector: string) {
  return new Promise<boolean>((resolve) => {
    if (!document.querySelector(selector)) {
      return resolve(true);
    }

    const observer = new MutationObserver((_mutations) => {
      if (!document.querySelector(selector)) {
        resolve(true);
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  });
}

export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

export const isSafari = () => {
  if (typeof window !== undefined) {
    return /^((?!chrome|android).)*safari/i.test(window?.navigator?.userAgent);
  }
};

export const isColorDark = (color: string): boolean => {
  // Check if the color is in rgba format
  const rgbaMatch = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

  if (rgbaMatch) {
    // Extract RGB values from rgbaMatch
    const red = parseInt(rgbaMatch[1]!, 10);
    const green = parseInt(rgbaMatch[2]!, 10);
    const blue = parseInt(rgbaMatch[3]!, 10);
    // Calculate luminance (brightness) of the color
    const luminance = 0.2126 * red + 0.7152 * green + 0.0722 * blue;
    // Determine if the color is dark based on its luminance
    return luminance < 128;
  }

  return false; // For simplicity, assuming it's not dark
};
